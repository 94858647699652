@import "../css/variables.module";

.excalidraw {
  .publish-library {
    &__fields {
      display: flex;
      flex-direction: column;

      label {
        padding: 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-weight: 500;
          font-size: 1rem;
          color: $oc-gray-6;
        }
        input,
        textarea {
          width: 70%;
          padding: 0.6em;
          font-family: var(--ui-font);
        }

        .required {
          color: $oc-red-8;
          margin: 0.2rem;
        }
      }
    }

    &__buttons {
      display: flex;
      padding: 0.2rem 0;
      justify-content: flex-end;

      .ToolIcon__icon {
        min-width: 2.5rem;
        width: auto;
        font-size: 1rem;
      }

      .ToolIcon_type_button {
        margin-left: 1rem;
        padding: 0 0.5rem;
      }

      &--confirm.ToolIcon_type_button {
        background-color: $oc-blue-6;

        &:hover {
          background-color: $oc-blue-8;
        }
      }

      &--cancel.ToolIcon_type_button {
        background-color: $oc-gray-5;
        &:hover {
          background-color: $oc-gray-6;
        }
      }

      .ToolIcon__icon {
        color: $oc-white;
        .Spinner {
          --spinner-color: #fff;
          svg {
            padding: 0.5rem;
          }
        }
      }
    }

    .selected-library-items {
      display: flex;
      padding: 0 0.8rem;
      flex-wrap: wrap;

      .single-library-item-wrapper {
        width: 9rem;
      }
    }

    &-warning {
      color: $oc-red-6;
    }

    &-note {
      padding: 1em;
      font-style: italic;
      font-size: 14px;
      display: block;
    }
  }
}
