@import "open-color/open-color";
@import "../css/variables.module";

.dyte-sync {
  background-color: rgb(221, 14, 14);
}

.dyte-sync svg {
  color: white;
}

.layer-ui__sidebar {
  position: absolute;
  top: var(--sat);
  bottom: var(--sab);
  right: var(--sar);
  z-index: 5;

  box-shadow: var(--shadow-island);
  overflow: hidden;
  border-radius: var(--border-radius-lg);
  margin: var(--space-factor);
  width: calc(#{$right-sidebar-width} - var(--space-factor) * 2);

  .Island {
    box-shadow: none;
  }

  .ToolIcon__icon {
    border-radius: var(--border-radius-md);
  }

  .ToolIcon__icon__close {
    .Modal__close {
      width: calc(var(--space-factor) * 7);
      height: calc(var(--space-factor) * 7);
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-text);
    }
  }

  .layer-ui__library-message {
    padding: 10px 20px;
    max-width: 200px;

    .Island {
      --padding: 0;
      background-color: var(--island-bg-color);
      border-radius: var(--border-radius-lg);
      padding: calc(var(--padding) * var(--space-factor));
      position: relative;
      transition: box-shadow 0.5s ease-in-out;
    }
  }
}

.excalidraw {
  .layer-ui__wrapper.animate {
    transition: width 0.1s ease-in-out;
  }

  .layer-ui__wrapper {
    // when the rightside sidebar is docked, we need to resize the UI by its
    // width, making the nested UI content shift to the left. To do this,
    // we need the UI container to actually have dimensions set, but
    // then we also need to disable pointer events else the canvas below
    // wouldn't be interactive.
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: var(--zIndex-layerUI);

    &__top-right {
      display: flex;
    }

    &__footer {
      width: 100%;

      &-right {
        z-index: 100;
        display: flex;
      }
    }

    .zen-mode-transition {
      transition: transform 0.5s ease-in-out;

      :root[dir="ltr"] &.transition-left {
        transform: translate(-999px, 0);
      }

      :root[dir="ltr"] &.transition-right {
        transform: translate(999px, 0);
      }

      :root[dir="rtl"] &.transition-left {
        transform: translate(999px, 0);
      }

      :root[dir="rtl"] &.transition-right {
        transform: translate(-999px, 0);
      }

      :root[dir="ltr"] &.layer-ui__wrapper__footer-left--transition-left {
        transform: translate(-76px, 0);
      }

      :root[dir="rtl"] &.layer-ui__wrapper__footer-left--transition-left {
        transform: translate(76px, 0);
      }

      &.layer-ui__wrapper__footer-left--transition-bottom {
        transform: translate(0, 92px);
      }
    }

    .disable-zen-mode {
      height: 30px;
      position: absolute;
      bottom: 10px;

      [dir="ltr"] & {
        right: 15px;
      }

      [dir="rtl"] & {
        left: 15px;
      }

      font-size: 10px;
      padding: 10px;
      font-weight: 500;
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s linear 0s, opacity 0.5s;

      &--visible {
        opacity: 1;
        visibility: visible;
        transition: visibility 0s linear 300ms, opacity 0.5s;
        transition-delay: 0.8s;
      }
    }

    .layer-ui__wrapper__footer-center {
      pointer-events: none;

      & > * {
        pointer-events: all;
      }
    }

    .footerActions {
      z-index: 10;
    }

    .layer-ui__wrapper__footer-left,
    .layer-ui__wrapper__footer-right,
    .disable-zen-mode--visible {
      pointer-events: all;
    }

    .layer-ui__wrapper__footer-left {
      margin-bottom: 0.2em;
    }

    .layer-ui__wrapper__footer-right {
      margin-top: auto;
      margin-bottom: auto;
      margin-inline-end: 1em;
    }
  }
}
